<template>
  <div class="h-screen flex flex-col">
    <!--tabs-->
    <div class="flex-none border border-solid d-theme-border-grey-light">
      <div class="p-1 shadow-none rounded-none d-theme-dark-bg">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto" :class="{'opacity-50 pointer-events-none': loading}">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{
                  'text-primary font-bold bg-primary-transparent-25': tabs.active === tab.code,
                  'hover-bg-primary-transparent-25': tabs.active !== tab.code
                }"
                @click="tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-t-0 border-b-0 border-l-0 border-r">
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--main-->
    <div class="flex-1 w-full h-full">
      <DocumentView v-if="tabs.active === 'DOCUMENT'"
                    ref="documentView"
                    :report="report"
                    :reportParameters="reportParameters"
                    @loading="loading = $event"/>

      <GridView v-if="tabs.active === 'GRID'"
                ref="gridView"
                :report="report"
                :reportParameters="reportParameters"
                @loading="loading = $event"/>
    </div>
  </div>
</template>

<script>
import DocumentView from '@/views/pages/reports/main/sections/DocumentView'
import GridView from '@/views/pages/reports/main/sections/GridView'

export default {
  name: 'ResultSection',
  props: ['selectedReport', 'selectedReportParameters'],
  components: { GridView, DocumentView },
  watch: {
    loading (isLoading) {
      this.emitIsLoading(isLoading)
    }
  },
  data () {
    return {
      loading: false,
      tabs: {
        active: 'DOCUMENT',
        items: [
          { code: 'DOCUMENT', title: 'Document View' },
          { code: 'GRID', title: 'Grid View' }
        ]
      },
      report: null,
      reportParameters: null
    }
  },
  methods: {
    run () {
      this.report = this.selectedReport
      this.reportParameters = this.selectedReportParameters

      // set timeout to fix bug
      setTimeout(() => {
        const activeTab = this.tabs.active
        if (activeTab === 'DOCUMENT') {
          this.$refs.documentView.getResult()
        } else if (activeTab === 'GRID') {
          this.$refs.gridView.getResult()
        }
      }, 1)
    },

    emitIsLoading (isLoading) {
      this.$emit('loading', isLoading)
    }
  }
}
</script>
