<template>
  <div class="box-content-height flex flex-col">
    <!--header-->
    <div class="flex-none border border-t-0 border-solid d-theme-border-grey-light">
      <div v-if="report" class="flex flex-row flex-wrap sm:h-8 justify-between space-x-3" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex flex-row items-center ml-2">
          <p class="text-sm font-bold">{{ report.description }}</p>
        </div>
        <div class="flex flex-row">
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs">
            <vs-button type="flat" color="primary" size="small" class="px-3 font-bold" icon-pack="feather" icon="icon-download">Export</vs-button>
            <vs-dropdown-menu>
              <vs-dropdown-item class="p-1" @click="download('xlsx')">
                <div class="flex flex-row space-x-2">
                  <feather-icon icon="FileIcon" svg-classes="h-4 w-4"/>
                  <span class="whitespace-no-wrap text-sm">Excel</span>
                </div>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="w-1 d-theme-dark-bg opacity-20"></div>
        </div>
      </div>
    </div>

    <!--result-->
    <div class="flex-1 overflow-scroll">
      <div class="w-full h-full">
        <p class="p-2 text-danger text-sm" v-if="errorMessage">{{ errorMessage }}</p>
        <table v-else class="table-auto table-sticky border-collapse w-full">
          <thead>
            <tr class="d-theme-dark-bg">
              <th v-for="(column, index) in tableColumns" :key="index" class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-medium text-xs text-center whitespace-no-wrap">
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td v-for="(column, index) in tableColumns" :key="index" class="text-xs text-left align-text-top px-2 py-1 border border-solid d-theme-border-grey-light font-medium whitespace-pre" :class="{'text-right': !isNaN(item[column])}">
                {{ item[column] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import _ from 'lodash'

export default {
  name: 'GridView',
  props: ['report', 'reportParameters'],
  mounted () {
    if (this.report) {
      this.getResult()
    }
  },
  computed: {
    tableColumns () {
      if (this.items.length < 1) return []
      const item = this.items[0]
      return _.keys(item)
    },
    queryParameters () {
      const parameters = {}
      _.each(this.reportParameters, param => {
        parameters[param.name] = param.value ? (param.value.toString().trim() || null) : null
      })
      return parameters
    }
  },
  watch: {
    loading (isLoading) {
      this.emitIsLoading(isLoading)
    }
  },
  data () {
    return {
      loading: false,
      errorMessage: null,
      items: []
    }
  },
  methods: {
    getResult () {
      this.loading = true
      this.errorMessage = null

      const params = {
        id: this.report.id,
        parameters: this.queryParameters
      }

      ReportRepository.run(params)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.errorMessage = error.response.data.message
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    download (convertTo) {
      window.location = ReportRepository.generateReportUrl(
        this.report.id,
        'grid',
        this.queryParameters,
        convertTo,
        true
      )
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    emitIsLoading (isLoading) {
      this.$emit('loading', isLoading)
    }
  }
}
</script>

<style scoped>
.box-content-height {
  height: calc(100vh - 32px);
}

.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
