<template>
  <div>
    <div class="h-screen overflow-hidden">
      <div class="h-screen flex flex-row flex-wrap">
        <!--LEFT-->
        <div class="md:w-3/12 w-full border border-solid border-r-2 d-theme-border-grey-light">
          <div class="h-screen flex flex-col">
            <!--search-->
            <div class="flex-none flex flex-row items-center border border-solid d-theme-border-grey-light d-theme-dark-bg">
              <vs-input v-model="search" @keyup.enter="getReports" icon-no-border icon-pack="feather" icon="icon-search" placeholder="Cari" class="vs-input-no-border vs-input-no-shdow-focus w-full rounded-none"/>
              <feather-icon @click="getReports" icon="RefreshCwIcon" svgClasses="h-4 w-4" class="p-2 cursor-pointer hover:text-primary" :class="{'opacity-50 pointer-events-none': loading.getReports}"/>
            </div>

            <!--report list-->
            <div class="flex-none d-theme-dark-bg">
              <div class="h-screen-50 overflow-scroll">
                <div v-if="loading.getReports" class="w-full h-full flex flex-col justify-center items-center">
                  <p class="text-xs">Loading...</p>
                </div>
                <table v-else class="table-fixed table-sticky border-collapse w-full">
                  <thead>
                    <tr class="d-theme-dark-bg">
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 text-xs text-center whitespace-no-wrap w-20">Index</th>
                      <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 text-xs text-center whitespace-no-wrap">Description</th>
                    </tr>
                  </thead>
                  <tbody v-for="group in reportGroups" :key="group.uuid">
                    <!--group-->
                    <tr class="cursor-pointer hover-bg-primary-transparent-10" @click="group.expanded = !group.expanded">
                      <td class="border border-solid d-theme-border-grey-light p-1" colspan="2">
                        <div class="flex items-center" :class="{'opacity-50': group.is_all_items_hidden}">
                          <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                          <span class="text-xs font-semibold">Report: {{ group.name }}</span>
                        </div>
                      </td>
                    </tr>
                    <!--group items-->
                    <template v-if="group.expanded">
                      <tr v-for="(item, index) in group.items" :key="item.uuid" @click="onReportSelected(item)" class="cursor-pointer" :class="{'bg-primary-transparent-25': selectedReport === item, 'hover-bg-primary-transparent-10': selectedReport !== item, 'opacity-50': item.is_hidden}">
                        <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center font-medium">{{ index + 1 }}</td>
                        <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left font-medium">{{ item.description }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>

            <!--actions-->
            <div class="flex-none d-theme-dark-bg border border-solid border-t-2 border-b d-theme-border-grey-light">
              <div class="flex flex-row flex-wrap justify-between p-2">
                <div class="flex-none flex flex-row items-center space-x-4">
                  <router-link v-if="$can('view_report_access_control')" :to="{ name: 'report.accessControl' }" class="flex text-dark flex-col items-center justify-center hover:text-blue">
                    <feather-icon icon="UserCheckIcon" svgClasses="h-4 w-4"/>
                    <p class="text-xs mt-1 font-semibold">ACL</p>
                  </router-link>
                  <router-link v-if="$can('create_report')" :to="{ name: 'report.add' }" class="flex text-dark flex-col items-center justify-center hover:text-success">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
                    <p class="text-xs mt-1 font-semibold">New</p>
                  </router-link>
                  <component :is="selectedReport ? 'router-link' : 'div'" v-if="$can('update_report')" :to="{ name: 'report.edit', params: { idReport: selectedReport ? selectedReport.id : null } }" class="flex text-dark flex-col items-center justify-center hover:text-warning" :class="{'opacity-50 pointer-events-none': !selectedReport}">
                    <feather-icon icon="EditIcon" svgClasses="h-4 w-4"/>
                    <p class="text-xs mt-1 font-semibold">Edit</p>
                  </component>
                  <component :is="selectedReport ? 'router-link' : 'div'" v-if="$can('update_report')" :to="{ name: 'report.design', params: { idReport: selectedReport ? selectedReport.id : null } }" class="flex text-dark flex-col items-center justify-center hover:text-primary" :class="{'opacity-50 pointer-events-none': !selectedReport}">
                    <feather-icon icon="LayoutIcon" svgClasses="h-4 w-4"/>
                    <p class="text-xs mt-1 font-semibold">Design</p>
                  </component>
                  <div v-if="$can('delete_report')" class="flex flex-col items-center justify-center cursor-pointer hover:text-danger" @click="confirmDelete" :class="{'opacity-50 pointer-events-none': !selectedReport}">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4"/>
                    <p class="text-xs mt-1 font-semibold">Delete</p>
                  </div>
                </div>
                <div class="flex-1 flex flex-row items-center justify-end">
                  <vs-button color="primary" class="px-3" icon-pack="feather" icon="icon-play" :disabled="!selectedReport || loading.getResult" @click="run">
                    {{ loading.getResult ? 'Running...' : 'Run' }}
                  </vs-button>
                </div>
              </div>
            </div>

            <!--report params text-->
            <div class="flex-none border-t-0 border border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
              <div class="flex flex-row justify-between items-center p-2">
                <p class="text-xs font-medium">Report Parameters</p>
                <feather-icon v-if="selectedReport" @click="refreshReportParameters" icon="RefreshCwIcon" svgClasses="h-4 w-4" class="cursor-pointer hover:text-primary"/>
              </div>
            </div>

            <!--report params field-->
            <div class="flex-1 overflow-scroll d-theme-dark-bg">
              <Parameters ref="parameters"
                          :idReport="selectedReport ? selectedReport.id : null"
                          :parameters.sync="reportParameters"/>
            </div>

          </div>
        </div>

        <!--RIGHT-->
        <div class="md:w-9/12 w-full">
          <ResultSection ref="resultSection"
                         :selectedReport="selectedReport"
                         :selectedReportParameters="reportParameters"
                         @loading="loading.getResult = $event"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import ResultSection from '@/views/pages/reports/main/sections/ResultSection'
import Parameters from '@/views/pages/reports/main/parts/Parameters'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'Report',
  components: {
    Parameters,
    ResultSection
  },
  mounted () {
    this.getReports()
  },
  data () {
    return {
      loading: {
        getReports: false,
        getResult: false
      },
      reportGroups: [],
      reportParameters: [],
      selectedReport: null,
      search: null
    }
  },
  methods: {
    getReports () {
      this.selectedReport = null
      this.loading.getReports = true

      const params = { search: this.search || '' }
      ReportRepository.get(params)
        .then(response => {
          this.reportGroups = this.buildReportGroups(_.cloneDeep(response.data.data))
          this.initSelectedReport()
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getReports = false
        })
    },

    delete () {
      const id = this.selectedReport.id
      ReportRepository.delete(id)
        .then(response => {
          this.getReports()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: `Yakin ingin menghapus report ${this.selectedReport.description}?`,
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete() }
      })
    },

    run () {
      this.$refs.resultSection.run()
    },

    refreshReportParameters () {
      this.$refs.parameters.getParameters()
    },

    onReportSelected (item) {
      this.selectedReport = item
      this.changeRouteQueryParamSelectedReportId(item.id)
    },

    changeRouteQueryParamSelectedReportId (newReportId) {
      const currentSelectedReportId = (this.$route.query.selected_report_id || '').toString()
      const newSelectedReportId = newReportId.toString()
      if (currentSelectedReportId !== newSelectedReportId) {
        this.$router.push({ name: 'report', query: { selected_report_id: newSelectedReportId } })
      }
    },

    initSelectedReport () {
      _.each(this.reportGroups, (group, groupIndex) => {
        const groupItems = group.items
        _.each(groupItems, item => {
          if (item.id.toString() === (this.$route.query.selected_report_id || '').toString()) {
            this.selectedReport = item
            this.reportGroups[groupIndex].expanded = true
          }
        })
      })
    },

    buildReportGroups (data) {
      const generateGroupItems = (group) => {
        const itemsFiltered = _.filter(data, item => (item.group === group))
        return _.map(itemsFiltered, item => {
          item.uuid = uuid()
          return item
        })
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.group), item => {
          const items = generateGroupItems(item.group)
          return {
            uuid: uuid(),
            name: item.group,
            items: items,
            is_all_items_hidden: _.filter(items, it => !it.is_hidden).length < 1,
            expanded: !!this.search
          }
        })
      }

      return generateGroups()
    }
  }
}
</script>

<style scoped>
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
