<template>
  <div class="box-content-height flex flex-col">
    <!--header-->
    <div class="flex-none border border-t-0 border-solid d-theme-border-grey-light">
      <div v-if="report" class="flex flex-row flex-wrap sm:h-8 justify-between space-x-3" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex flex-row items-center ml-2">
          <p class="text-sm font-bold">{{ report.description }}</p>
        </div>
        <div class="flex flex-row">
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs" v-if="report.template_path">
            <div class="flex flex-row items-center space-x-1 px-3">
              <feather-icon icon="DownloadIcon" svgClasses="h-4 w-4"/>
              <p class="text-xs font-semibold">Export</p>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item class="p-1" @click="download('xlsx')">
                <div class="flex flex-row space-x-2">
                  <feather-icon icon="FileIcon" svg-classes="h-4 w-4"/>
                  <span class="whitespace-no-wrap text-sm">Excel</span>
                </div>
              </vs-dropdown-item>
              <vs-dropdown-item class="p-1" @click="download('pdf')">
                <div class="flex flex-row space-x-2">
                  <feather-icon icon="FileIcon" svg-classes="h-4 w-4"/>
                  <span class="whitespace-no-wrap text-sm">PDF</span>
                </div>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>
    </div>

    <!--result-->
    <div class="flex-1 overflow-scroll">
      <div class="w-full h-full">
        <template v-if="report && !report.template_path">
          <div class="flex flex-col w-full h-full items-center justify-center">
            <p class="text-sm">Report ini belum memiliki document view</p>
          </div>
        </template>
        <template v-else>
          <iframe ref="myIframe" v-if="reportUrl && isIframeShowed" :src="reportUrl" class="w-full h-full border-0" @load="onIFrameLoaded"></iframe>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import _ from 'lodash'

export default {
  name: 'DocumentView',
  props: ['report', 'reportParameters'],
  mounted () {
    if (this.report) {
      this.getResult()
    }
  },
  computed: {
    queryParameters () {
      const parameters = {}
      _.each(this.reportParameters, param => {
        parameters[param.name] = param.value ? (param.value.toString().trim() || null) : null
      })
      return parameters
    }
  },
  watch: {
    loading (isLoading) {
      this.emitIsLoading(isLoading)
    }
  },
  data () {
    return {
      loading: false,
      isIframeShowed: true,
      loadingDownload: false,
      reportUrl: null
    }
  },
  methods: {
    getResult () {
      if (this.report.template_path) {
        const url = ReportRepository.generateReportUrl(this.report.id, 'document', this.queryParameters)
        this.loading = true
        if (url === this.reportUrl) {
          this.refreshIframe()
        } else {
          this.reportUrl = url
        }
      }
    },

    refreshIframe () {
      setTimeout(() => {
        this.isIframeShowed = false
      }, 10)
      setTimeout(() => {
        this.isIframeShowed = true
      }, 20)
    },

    download (convertTo) {
      window.location = ReportRepository.generateReportUrl(
        this.report.id,
        'document',
        this.queryParameters,
        convertTo,
        true
      )
    },

    onIFrameLoaded () {
      this.loading = false
    },

    emitIsLoading (isLoading) {
      this.$emit('loading', isLoading)
    }
  }
}
</script>

<style scoped>
.box-content-height {
  height: calc(100vh - 32px);
}
</style>
